<template>
  <v-container class="pa-0">
    <v-row dense>
      <v-col cols="2">
        <v-btn
          aut-prev
          v-bind="navAttributes"
          :class="navAttributes.classes"
          @click="$emit('prev')"
        >
          <v-icon left> mdi-chevron-left </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="8">
        <div class="d-flex">
          <v-spacer v-if="!isSmallScreen" />
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="col behaviour_centered"
              >
                <span aut-title class="pt-lg-1 mr-lg-1 behavior_clickable">
                  {{ title }}
                </span>
              </v-btn>
            </template>
            <v-date-picker
              v-model="viewDate"
              type="month"
              no-title
              @input="dateMenu = false"
            >
            </v-date-picker>
          </v-menu>
          <v-menu v-if="showTypeControl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="behaviour_centered col"
                outlined
                text
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                aut-type-control
              >
                <span>{{ type }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list aut-type-menu>
              <v-list-item @click="$emit('change:type', 'day')">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('change:type', 'week')">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('change:type', 'month')">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer v-if="!isSmallScreen" />
        </div>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          aut-next
          v-bind="navAttributes"
          :class="navAttributes.classes"
          @click="$emit('next')"
        >
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CalendarHeader",
  props: {
    attributes: Object,
    title: {},
    type: String,
    date: {},
  },
  data() {
    return {
      dateMenu: false,
    };
  },
  computed: {
    navAttributes() {
      return this.attributes?.nav || {};
    },
    showTypeControl() {
      return this.attributes?.type_control || false;
    },
    viewDate: {
      get() {
        const formatDate = this.$options.filters.date;
        return formatDate(this.date || "");
      },
      set(date) {
        this.$emit("change:date", new Date(date));
      },
    },
  },
};
</script>
